<template>
  <section class="hero">
    <div class="hero-body">
      <div class="card" v-for="dl in dls" :key="dl.url">
        <div class="card-header">
          <p class="card-header-title">{{ dl.friendly }}</p>
        </div>
        <footer
          class="card-footer"
          :style="{
            padding: '16px',
          }"
        >
          <a :href="dl.url">
            <b-button
              class="is-primary"
              :style="{
                backgroundColor: '#1062FE',
              }"
            >
              Download
            </b-button>
          </a>
        </footer>
      </div>
      <b-field>
        <b-switch v-model="showOthers">Show Others</b-switch>
      </b-field>
    </div>
  </section>
</template>

<script>
const dls = [
  {
    osType: "Windows",
    friendly: "Windows (x64)",
    url: "https://files.dojnaz.cloud/DojnazCloudDesktop/cc4d9423c00f231c281f99c1e8edf3c5/latest/win32/x64/DojnazCloudDesktop.exe",
  },
  {
    osType: "Linux",
    friendly: "Linux (rpm x64)",
    url: "https://files.dojnaz.cloud/DojnazCloudDesktop/cc4d9423c00f231c281f99c1e8edf3c5/latest/linux/x64/DojnazCloudDesktop.rpm",
  },
];
export default {
  data() {
    const osType =
      navigator.appVersion.indexOf("Win") != -1
        ? "Windows"
        : navigator.appVersion.indexOf("Linux") != -1
        ? "Linux"
        : "Unsupported";
    return {
      osType,
      dls: dls.filter((dl) => osType == "Unsupported" || osType == dl.osType),
      showOthers: false,
    };
  },
  watch: {
    showOthers(showOthers) {
      this.dls = dls.filter(
        (dl) =>
          this.osType == "Unsupported" || this.osType == dl.osType || showOthers
      );
    },
  },
};
</script>

<style scoped>
.card:not(:last-child) {
  margin-bottom: 25px;
}
</style>